var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "mew-overlay",
        {
          attrs: {
            footer: _vm.footer,
            "show-overlay": _vm.open,
            title: _vm.typeTitle,
            close: _vm.close,
            back: _vm.isOverview ? null : _vm.goBack,
            "content-size": "large",
          },
        },
        [
          _vm.isOverview
            ? _c("create-wallet-software-overview", {
                on: { typeSelect: _vm.setType },
              })
            : _vm.isKeystore
            ? _c("create-wallet-keystore", {
                attrs: { "handler-create-wallet": _vm.walletHandler },
              })
            : _vm.isMnemonic
            ? _c("create-wallet-mnemonic-phrase", {
                attrs: { "handler-create-wallet": _vm.walletHandler },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }